
import {computed, defineComponent, onMounted, ref} from "vue";
import {CaseManagerSelect, CompanySelect, LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {PERIOD} from "@/core/config/Constant";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import Maps from "@/views/case/management/Maps.vue";

export default defineComponent({
  name: "Hospitalizations",
  components: {Maps, DateTimeSelect, BaseRadio, BaseSelect, DateTimeFormat, KtDatatable, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams(' Hospitalizations',
        [
          {link: false, router: '', text: ' Hospitalizations'}
        ]
      )
    })
    const filterObject = ref({
      inpatient: true,
      clientId: '',
      managerId: '',
      status: 'ACTIVE',
      period: '',
      from: '',
      to: ''
    })
    const hospitalizations = computed(() => store.state.ProviderModule.hospitalizations);
    const tableHeader = [
      {
        name: "Case#",
        key: "caseNumber",
        sortable: true,
      },
      {
        name: "Service Dates",
        key: "dates",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Provider#",
        key: "provider",
        sortable: true,
      },
    ]
    return {
      ...LoadFilterObjects(Actions.LOAD_HOSPITALIZATIONS, filterObject.value, ['caze']),
      hospitalizations,
      filterObject,
      ...CompanySelect(),
      ...CaseManagerSelect(),
      tableHeader,
      PERIOD,
      ...LoadPanel()
    }
  },

  methods: {
    discardEvent() {
      this.filterObject.clientId = ''
      this.filterObject.managerId = ''
      this.updateFilterObject(this.filterObject);
    },
    searchEvent() {
      this.updateFilterObject(this.filterObject)
    }
  }
})
