
import {defineComponent, onMounted, watch} from 'vue'
import {Loader} from "@googlemaps/js-api-loader";

export default defineComponent({
  name: "Maps",
  props: {
    data: {required: true, type: Object}
  },
  setup(props) {
    const loader = new Loader({
      apiKey: "AIzaSyDjfqpMFaMOaiEzg6cIVSgXswsPBKkXFyM",
      version: "weekly",
    });

    const build = async (data: any) => {
      const {Map} = await loader.importLibrary("maps");
      const {AdvancedMarkerElement} = await loader.importLibrary("marker");
      const doc = document?.getElementById("maps-provider");
      // The map, centered at Uluru'
      let map;
      if (data.length > 0) {
        if (doc) {
          map = new Map(doc, {
            zoom: 4,
            center: {lat: Number(props.data[0].lat), lng: Number(props.data[0].lng)},
            mapId: 'DEMO_MAP_ID'
          });


          data.forEach(item => {
            const priceTag = document.createElement('div');
            priceTag.textContent = item.name;
            priceTag.className  ='price-tag'
             new AdvancedMarkerElement({
              map: map,
              gmpClickable: true,
              position: {lat: Number(item.lat), lng: Number(item.lng)},
              title: item.name,
              content: priceTag
            })

          })
        }
      }

    }
    onMounted(async () => {
      await build(props.data)
    })

    watch(() => props.data, async cb => {
      await build(cb)
    })
  }
})
